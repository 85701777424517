import primevue from "primevue/config"

import AutoComplete from "primevue/autocomplete"
import Button from "primevue/button"
import Calendar from "primevue/calendar"
import Card from "primevue/card"
import Checkbox from "primevue/checkbox"
import Column from "primevue/column"
import ColumnGroup from "primevue/columngroup"
import ConfirmDialog from "primevue/confirmdialog"
import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Divider from "primevue/divider"
import Dropdown from "primevue/dropdown"
import InlineMessage from "primevue/inlinemessage"
import InputNumber from "primevue/inputnumber"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import Menubar from "primevue/menubar"
import Message from "primevue/message"
import Paginator from "primevue/paginator"
import ProgressBar from "primevue/progressbar"
import Row from "primevue/row"
import Skeleton from "primevue/skeleton"
import Toast from "primevue/toast"
import Toolbar from "primevue/toolbar"

import ConfirmationService from "primevue/confirmationservice"
import ToastService from "primevue/toastservice"

import "primevue/resources/themes/lara-light-teal/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

export default {
  install: (app) => {
    app.use(primevue, {
      locale: {
        aria: {},
        today: "今日",
        clear: "クリア",
        accept: "はい",
        reject: "いいえ",
        dayNames: [
          "日曜日",
          "月曜日",
          "火曜日",
          "水曜日",
          "木曜日",
          "金曜日",
          "土曜日",
        ],
        dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
        dayNamesMin: ["日", "月", "火", "水", "木", "金", "土"],
        monthNames: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        monthNamesShort: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
        ],
      },
    })

    // 利用するコンポーネントを列挙する
    app.component("PAutoComplete", AutoComplete)
    app.component("PButton", Button)
    app.component("PCalendar", Calendar)
    app.component("PCard", Card)
    app.component("PCheckbox", Checkbox)
    app.component("PColumn", Column)
    app.component("PColumnGroup", ColumnGroup)
    app.component("PConfirmDialog", ConfirmDialog)
    app.component("PDataTable", DataTable)
    app.component("PDialog", Dialog)
    app.component("PDivider", Divider)
    app.component("PDropdown", Dropdown)
    app.component("PInlineMessage", InlineMessage)
    app.component("PInputNumber", InputNumber)
    app.component("PInputSwitch", InputSwitch)
    app.component("PInputText", InputText)
    app.component("PMenubar", Menubar)
    app.component("PMessage", Message)
    app.component("PPaginator", Paginator)
    app.component("PProgressBar", ProgressBar)
    app.component("PRow", Row)
    app.component("PSkeleton", Skeleton)
    app.component("PToast", Toast)
    app.component("PToolbar", Toolbar)

    // 利用するサービスを列挙する
    app.use(ConfirmationService)
    app.use(ToastService)
  },
}
