import { createApp } from "vue"
import VueKonva from "vue-konva"
import App from "./App.vue"
import router from "./router"
import VueGtag from "vue-gtag"
import primevue from "./plugins/primevue"
import "./assets/styles/layout.scss"

createApp(App)
  .use(router)
  .use(VueKonva)
  .use(VueGtag, {
    config: { 
      id: "G-GCEXFN70YP",
    },
  }, router)
  .use(primevue)
  .mount("#app")